import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Video API Service on Serverless Engine",
  "author": "tzookb",
  "type": "post",
  "date": "2019-01-30T10:32:08.000Z",
  "url": "/2019/01/video-api-service-on-serverless-engine/",
  "categories": ["Python"],
  "tags": ["Proud Of", "python"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Brief On a recent project I did we provided a service for providing video data for many different clients like browsers, mobile apps and more. This service was in charge of fetching data from other external services and hiding all the “complexity” from the clients. The service authenticated the calling user with a passed JWT token.`}</h2>
    <p>{`After that it called another service to fetch video data that checked if user the authorized to watch the video. With that it will get the last viewed point in the video, so the user will have a great experience as continuing the video from his last watched point. And last item was to check if the user has signed the proper waiver for watching the video (fitness and health videos). `}</p>
    <h2>{`Tech Stack We used python 3.6 as of programming language, with the “serverless” framework. It was deployed to lambda functions.`}</h2>
    <p>{`For automation we used Travis.`}</p>
    <p>{`We used git as our version control on Github.`}</p>
    <p>{`Our testing was built with pytest both for unit tests and integration tests. `}</p>
    <h2>{`Flow At first we ran all the code and external services http calls as sequential code one after another.`}</h2>
    <p>{`But as we decided to improve the response time, we moved some independent calls to run in parallel and we cut the response time in almost 40%. `}</p>
    <h2>{`Data storage We used DynamoDB from aws. For storing waivers signing and for storing cached responses from other services.`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      